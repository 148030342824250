import "./ganacheEdu.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ganacheEdu from "../assets/GANACHEEDU.png";
let GanacheEdu = () => {
  let eduArray = [
    {
      img: "https://etimg.etb2bimg.com/photo/94512637.cms",
      h1: "Web 3.0",
      desc: `Web 3.0 is characterized by a decentralized and distributed architecture, where data is owned and controlled by individuals rather than centralized entities. It emphasizes on creating a more secure, transparent, and trustworthy online environment, where users can interact and transact with each other without intermediaries.
      One of the key features of Web 3.0 is the use of smart contracts, which are self-executing contracts with the terms of the agreement between buyer and seller being directly written into code. Smart contracts help to automate and secure transactions, reduce costs, and increase efficiency..`,
      link: "https://university.alchemy.com/",
    },
    {
      img: "https://www.cnet.com/a/img/resize/2715aac9e2099982b092dc16e77b25fe0d5a4734/hub/2022/01/18/141595b6-ec1b-4aab-a70f-d2882821e885/gettyimages-1348369701.jpg?auto=webp&fit=crop&height=675&width=1200",
      h1: "Metaverse",
      desc: `Metaverse is a digital universe that allows users to interact with each other in a virtual environment. It is a next-generation internet that has been described as a combination of virtual reality, blockchain, and cryptocurrency. In Metaverse, users can create their own avatars, own virtual land, build and develop applications, and trade virtual assets with others. It is built on blockchain technology, which ensures that transactions and ownership of assets are secure and transparent. Metaverse is believed to be the future of the internet, where users can experience a new form of social interaction, commerce, and entertainment. With the rise of Metaverse, there are endless possibilities for creating new types of businesses and applications that can drive the growth of the virtual economy.`,
      link: "https://www.edverse.com/",
    },
    {
      img: "https://bernardmarr.com/img/What%20Is%20Blockchain%20A%20Super%20Simple%20Guide%20Anyone%20Can%20Understand.png",
      h1: "Blockchain",
      desc: `Blockchain is a revolutionary technology that has the potential to transform a variety of industries, from finance to healthcare, supply chain, and beyond. At its core, a blockchain is a decentralized, distributed ledger that allows for secure, transparent, and immutable record-keeping.
      One of the key features of blockchain is its ability to create a trustless system where all parties can verify transactions without relying on a central authority. This makes it an attractive solution for industries that require high levels of security and transparency.`,
      link: "https://university.alchemy.com/ethereum",
    },
    {
    img: "https://www.teknovatoren.no/wp-content/uploads/2019/02/Skjermbilde-2019-02-25-kl.-09.31.41.png",
      h1: "Blockchain Business Strategy",
      desc: `A blockchain business strategy should start with identifying the specific use cases and industries where blockchain can provide the most value. This could include supply chain management, digital identity verification, asset tracking, and more. Once the use cases are defined, businesses need to evaluate the technical requirements and resources needed to implement blockchain, including choosing the appropriate blockchain platform and building the necessary infrastructure`,
      link: "https://101blockchains.com/blockchain-business-strategy/#:~:text=A%20blockchain%20business%20strategy%20can,financial%20or%20non%2Dfinancial%20purposes.",
    },
  ];

  return (
    <>
      <h1 style={{ color: "white", marginBottom: "5rem", fontSize: "3rem" }}>
        {"Ganache Edu"}
      </h1>
      <section className="cards">
        {eduArray.map((el) => {
          return (
            <>
              <div className="card">
                <div className="upper">
                  <img src={el.img} />
                </div>
                <div className="lower">
                  <h1>{el.h1}</h1>
                  <p style={{ marginBottom: "1rem" }}>{el.desc}</p>
                  <Button
                    variant="contained"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      window.location.href = el.link;
                    }}
                  >
                    More
                  </Button>
                </div>
              </div>
            </>
          );
        })}
      </section>
      <hr />
      <section>
        <div className="join-community">
          <h1>Join as a Team</h1>
          <div>
          Join our team and be part of an exciting journey towards building innovative blockchain solutions. 
          We are looking for talented individuals who are passionate about blockchain technology and its potential to revolutionize industries. 
          As a team member, you will have the opportunity to work with experienced professionals in the field and contribute your skills towards the development of cutting-edge blockchain applications. If you are a developer, designer, marketer, or have skills that you think can be valuable to our team, please fill out the form below and we will get in touch with you.
          </div>
          <Button variant="contained" style={{margin: '0 2rem'}} onClick={()=> {
            window.location.href = 'https://forms.gle/Z3QqZcxRTD5faEp86'
          }}>Join Us</Button>
        </div>
      </section>
    </>
  );
};
export default GanacheEdu;
