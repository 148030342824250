import React, { useState } from "react";
import home__section__pic from "../assets/home__section__pic.png";
import marketing from "../assets/marketing.png";
import Influencer from '../assets/Influencer.png'
import dev from '../assets/dev.jpg'
import IDO from '../assets/IDO.png'
import Footer from "../components/Footer";
import logoBig from "../assets/logoBig.png";
import metaverse from '../assets/metaverse.avif'
import partner1 from "../assets/partner1.webp"/* 
import Iconawesomefacebook from "../assets/Icon awesome-facebook-f.png";
import Iconawesomeyoutube from "../assets/Icon awesome-youtube.png";
import Iconpaymentapplepay from "../assets/Icon payment-apple-pay.png";
import Iconsimplesamsung from "../assets/Icon simple-samsung.png";
import { Facebook, Instagram, Linkedin } from "react-feather"; */
import cubicpool from '../assets/cubicpool.png'
import Partnership from '../assets/Partnership.jpg'
import EXCHANGE from '../assets/EXCHANGE.jpg'
import lunas from '../assets/lunas.png'
import spirit from '../assets/spirit.jpg'
import CK from '../assets/CK.jpeg'
import gem from '../assets/gem.png'
import game from '../assets/game.png'
import insider from '../assets/insider.png'
import fish from '../assets/fish.png'
import solchick from '../assets/solchick.jpg'
import twitter from '../assets/twitter.png'
import telegram from '../assets/telegram.png'
import SKYX from '../assets/SKYX.png'
import CRDN from '../assets/CRDN.png'
import globe from '../assets/globe.png'
import aza from '../assets/aza.png'
import gagarin from '../assets/gagarin.png'
import  hero from '../assets/hero.png'
import light from '../assets/light.png'
import pay from '../assets/pay.jpg'
import x  from '../assets/x.png'
import bull from '../assets/bull.jpg'

let card
   = [
  {imgsrc: marketing, headers: 'MARKETING', content: ' Our Marketing team can help you push your campaigns through our social media and telegram channels. We can also perform AMAs in our channels '},
  {imgsrc: Influencer, headers: 'INFLUENCER', content: 'With a global network, we have the largest and most cost- effective influencer network in the world. we provide the best buisness environment for you. '},
  {imgsrc: dev, headers: 'DEVELOPMENT', content: 'We have partners with several development software companies, Game Studios, Web Development, UI Designers and Graphic Designers, So whatever you need, we can deliver! '},
  {imgsrc: IDO, headers: 'IDO NETWORK', content: 'We can help connect you with the best and most chosen launchpad in the cryptocurrency world, we have good relationships with well-known launchpad projects '},
  {imgsrc: Partnership, headers: 'PARTNER', content: 'Through our strategic partners, the project partners we have, as well as the exchange partners we have done, we can help projects develop and grow.'},
  {imgsrc: EXCHANGE, headers: 'EXCHANGE', content: 'We can help connect you with the best and most chosen Exchanges in the cryptocurrency world, we have good relationships with well-known Exchange '},
]

let partner = [
    {imgsrc: x, headers:'Xandar', content: 'A Play-to-Earn Adventure and Arena-based Massively Multiplayer Online Role-Playing AAA Game (MMORPG) with the concept of Octa-Attributes.', href: ['https://twitter.com/xandargames','https://t.me/xandar_community','https://xandar.games/']},
    {imgsrc: CK, headers: 'Crypto Kingdom Ventures', content:'Crypto kingdom ventures is a Decentralized project booster building a web of powerful entrepreneurs', href: ['https://twitter.com/_Cryptokingdom_', 'https://t.me/cryptokingdomventures', 'https://cryptokingdom.ventures/']},
    {imgsrc: fish, headers: 'Fish DAO', content: 'Fish DAO is a decentralized investment DAO, its mission statement is to try to democratize access to early-stage investment opportunities', href: ['https://twitter.com/FishDAO', 'http://t.me/fishdao', 'https://fishdao.io']},
    {imgsrc: cubicpool,headers:'Cubicpool', content: 'Cupicpool is aiming to bring early access to our community of Mandarin-speaking crypto enthusiasts', href: ['https://twitter.com/cubicpool', '', 'https://t.co/KpK9NtCgse']},
    {imgsrc: CRDN, headers: 'Cardence', content: 'Cardence is incubator and launchpad for top tier blockchain projects in the Gamefi , NFTs, Metaverse and Cardano.', href: ['https://twitter.com/cardence_io', 'https://t.me/cardence_chat','https://cardence.io/']},
    {imgsrc: SKYX, headers: 'SKYX Capital', content: 'Empowering blockchain & crypto projects with resources and access to capital, as well as providing added value for initiators & investors.' , href: ['https://twitter.com/skyx_fund', 'https://skyx.fund/home/home','https://t.me/skyx_community']},
    {imgsrc: insider, headers: 'Insider Venture', content: 'Insiders Ventures is a venture capital fund that specializes in investing in promising crypto startups.', href: ['https://twitter.com/INSIDERS_VEN','https://t.me/+jDF1aJqQCRdhMTRh', 'https://insiders.ventures/']},
    {imgsrc: aza,headers:'Aza Ventures',content: 'Investing in Only Solid FA Projects ',href: ['https://twitter.com/AzaGroups','https://t.me/Aza_Ventures','https://azagroups.co/']},
    {imgsrc: gagarin,headers: 'Gagarin Capital', content: 'GAGARIN is the expert #launchpad for incubating projects and launching #IDO. Follow us & #invest in promising startup', href: ['https://twitter.com/GAGARIN_World','https://t.me/Gagarin_Launchpad_Chat','https://gagarin.world/']},
    {imgsrc: hero,headers: 'Hero capital', content: 'Global Community based Venture firm investing in promising project of the future. Partner with 100+ KOLs', href: ['https://twitter.com/HeroGems_', 'https://t.co/sDlCpPWB0r','https://herogemsventure.com/']},
    {imgsrc: light, headers:'The Lighthouse Ventures', content:'Expertise in DeFi Research, Market Intelligence, Trading Experience, NFT Trend, Project Promotions locally and globally',  href: ['https://twitter.com/TheLighthouseVN','https://t.me/Tlh_official','https://thelighthouse.ventures/']},
    {imgsrc: spirit, headers: 'Spirit Ventures', content: 'Spirit Ventures mission is to share knowledge and opportunities about cryptocurrency and blockchain, connect the community with quality projects.', href: ['https://twitter.com/CryptoSpiritVN', 'https://t.me/spiritventures']},
    {imgsrc: game, headers: 'GameFi Capital', content: 'GameFi Aims to support the future of games and be the prime mover for the adaptation of Blockchain technology in Asia.', href: ['https://twitter.com/gameficapital', 'https://t.me/gameficapital', 'https://gameficapital.net/']},
    {imgsrc: pay, headers: 'Paycoin Capital', content:'RESEARCH | NEWS | INVEST | FUND RAISE | MARKETING ',href: ['']},
    {imgsrc: gem, headers: 'Gems Ventures', content: 'GEMS Ventures is a Venture Capital that help Investors to find potential projects blockchain for early investment.', href: ['https://twitter.com/gems_ventures', 'https://t.me/gemsglobal']},
  ]

let portfolio = [{imgsrc: solchick, headers: 'SOLCHICK', content: 'The Best Play to Earn Crypto Fantasy Game on Solana',href: ['https://twitter.com/SolChicksNFT', 'https://t.me/solchicksNF', 'https://www.solchicks.io/']},
    {imgsrc: x, headers:'Xandar', content: 'A Play-to-Earn Adventure and Arena-based Massively Multiplayer Online Role-Playing AAA Game (MMORPG) with the concept of Octa-Attributes.', href: ['https://t.me/xandar_community']},
    {imgsrc: bull, headers:'Bullstarter', content: 'Bullstarter is a decentralised fundraising platform supercharging the DeFi, NFT, Metaverse, and Gaming ecosystems.', href: ['https://t.me/bullstarter_io']}
  ]

/* function TeamCard({ image }) {
  return (
    <div className="team__section__content__entry">
      <img
        src={image}
        alt="teamImg" 
        className="team__section__content__entry__img"
      />
      <div className="team__section__content__entry__link">
        <a href="#" className="team__section__content__entry__link__entry">
          <Facebook size={20} color="currentColor" />
        </a>
        <a href="#" className="team__section__content__entry__link__entry">
          <Instagram size={20} color="currentColor" />
        </a>
        <a href="#" className="team__section__content__entry__link__entry">
          <Linkedin size={20} color="currentColor" />
        </a>
      </div>
      <div className="team__section__content__entry__content__heading">
        John Doe
      </div>
      <div className="team__section__content__entry__content">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries
      </div>
    </div>
  );
}
 */
function Home() {
  
  return (
    <>
      <div className="home__section">
        <img
          src={home__section__pic}
          alt="home__section__pic"
          className="home__section__pic"
          
        />
        <div className="home__section__heading">
          We strive to find, fund, and support true entrepreneurs
        </div>
        <div className="home__section__buttons">
          <a href="https://t.me/ganacheventures" className="home__section__buttons__btn home__section__buttons__btn__secondary">
              Join Community
          </a>
        </div>
      </div>
      <div className="feature__section">
        <div className="feature__section__heading">Features</div>
        <div className="feature__section__content">
          {card.map((each) => {
            return (
              <div className="feature__section__content__card">
                <div className="card__image">
                  <img src={each.imgsrc} />  
                </div>
                <div className="card__data">
                  <h2 className="card__header">{each.headers}</h2>
                  <div className="card__content">{each.content}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="about__section">
        <div className="about__section__left">
          <img src={home__section__pic} className="about__section__left__img"/>
        </div>
        <div className="about__section__right">
          <div className="about__section__right__heading">
            What is Ganache ventures?
          </div>
          <div className="about__section__right__info">
            Ganache Ventures focusing on developing early stage projects with good potential and benefiting our investors, with Ganache Ventures
            we hope that all investors will no longer feel and worry about teh demand for the fees are too high, we are here to prosper
            investors and support projects to the next level
          </div>
          
        </div>
      </div>
      {/* <div className="team__section">
        <div className="feature__section__heading"></div>
        <div className="team__section__content">
          <TeamCard image={teamImg1} />
          <TeamCard image={teamImg2} />
          <TeamCard image={teamImg3} />
          <TeamCard image={teamImg4} />
        </div>
      </div> */}
      <div className="partner__section">
        <div className="feature__section__heading">PARTNERS</div>
        <div className="partner__section__content">
          {partner.map((each) => {
            return (
              <>
                <div className="partner__card">
                  <div className="partner__card__image"><img src={each.imgsrc}></img></div>
                  <div className="partner__card__content">
                    <h2 className="partner__card__header">{each.headers}</h2>
                    <div className="partner__card__text">{each.content}</div>
                  </div>
                  <div className="partner__card__footer">
                    <a href={each.href[0]}><img src={twitter} /></a>
                    <a href={each.href[1]}><img src={telegram} /></a>
                    <a href={each.href[2]} ><img src={globe} /></a>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>

      <div className="portfolio__section">
        <div className="feature__section__heading">PORTFOLIO</div>
        <div className="portfolio__section__content">
          {portfolio.map((each) => {
            return (
              <>
                <div className="portfolio__card">
                  <div className="portfolio__card__image">
                    <img src={each.imgsrc} />
                  </div>
                  <div className="portfolio__card__content">
                    <h2 className="portfolio__card__header">{each.headers}</h2>
                    <div className="portfolio__card__text">{each.content}</div>
                    <div className="portfolio__card__logo"><a href={each.href}><img src={telegram} /></a></div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  );
}

export default Home;
