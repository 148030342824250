import React from "react";
import "./App.scss";
import Header from "./components/Header";
import Home from "./screens/Home";
import { Route, Routes } from "react-router-dom";
import Stake from "./screens/Stake";
import GanacheEdu from "./components/GanacheEdu"
function App() {
  return (
    <>
      <Header />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/ganacheedu" element={<GanacheEdu />} />
        </Routes>
      </div>
      
    </>
  );
}

export default App;

// Author : hammadhabib64@gmail.com | mehfoozijaz786@gmail.com
